var site = site || {};

Drupal.behaviors.signinWidgetFormatterV1 = (function ($) {
  var bps = Unison.fetch.all();
  var bp = Unison.fetch.now();
  var isMobile = parseInt(bp.width, 10) < parseInt(bps.landscape, 10);
  var userName = site.userInfoCookie.getValue('first_name');
  var $document = $(document);
  var behavior = {
    attached: false,
    $templateWrapper: null,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $siteHeader = $('.js-site-header--v1 .site-header__utility', context);
      var $template = $('.js-signin-widget-formatter-v1', context);
      var $signinWidget = $('.js-signin-widget-v1', $template);
      var $signinWidgetToggle = $('.js-signin-widget-toggle', $template);
      var showWidgetOnPageLoadMobile = $signinWidget.data('showToutOnLoadMobile');

      var $pageContent = $('.field-content', document);

      this.$templateWrapper = $template;

      var signedIn = Number(site.userInfoCookie.getValue('signed_in')) === 1 || Number(site.userInfoCookie.getValue('csr_logged_in')) === 1;

      // Signin widget needs to be displayed only for logged in users
      if (!signedIn) {
        return false;
      }

      $template.removeClass('hidden');
      $signinWidget.addClass('hidden');
      $pageContent.addClass('widget-margin-top');

      var updateToggleAriaLabel = function ($this) {
        if ($this.hasClass('signin-widget__formatter-toggle-expanded')) {
          $this.attr('aria-label', $this.attr('data-label-collapse'));
        } else {
          $this.attr('aria-label', $this.attr('data-label-expand'));
        }
      };

      // Expand/Collapse widget handler
      $signinWidgetToggle.on('click', function (e) {
        e.preventDefault();
        var $this = $(this);

        $this.toggleClass('signin-widget__formatter-toggle-expanded');
        $signinWidget.toggleClass('hidden');
        $document.trigger('signin-widget-displayed');
        updateToggleAriaLabel($this);
      });

      // Signin widget should be below global rotating offers banner
      // and above the gnav
      $template.insertBefore($siteHeader).removeClass('hidden');

      // Update username in the widget
      this.updateUserName();

      // Responsiveness for mobile
      Unison.on('change', function (bp) {
        isMobile = parseInt(bp.width, 10) < parseInt(bps.landscape, 10);
        if (isMobile) {
          $signinWidgetToggle.removeClass('signin-widget__formatter-toggle-expanded');
          updateToggleAriaLabel($signinWidgetToggle);
        }
      });

      $document.on('gnav.events.attached', function () {
        // If widget needs to be displayed on page load
        if (isMobile && showWidgetOnPageLoadMobile && !$.cookie('signin-widget-displayed')) {
          $signinWidgetToggle.trigger('click');
          $.cookie('signin-widget-displayed', 1, {
            expires: 1,
            path: '/'
          });
        }
      });
    },
    updateUserName: function () {
      /**
       * Update username
       * Get the current logged in username and replace ::USER_NAME::
       * string. For e.g., Hello Mike,
       */
      if (!userName) {
        return;
      }
      var $userNameContainer = $('.js-signin-widget-user-name', this.$templateWrapper);

      $userNameContainer.html($userNameContainer.html().replace('::USER_NAME::', userName));
    }
  };

  // Get username from existing RPC response
  $document.on('user.loaded', function (e, user) {
    if (!userName) {
      userName = userName ? userName : user.first_name;
      behavior.updateUserName();
    }
  });

  return behavior;
})(jQuery);
